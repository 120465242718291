import * as React from "react"
// import { Link } from "gatsby"

import { Container, Row, Col  } from 'react-bootstrap'

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Container className="singleCol">
      <h1 className="text-center my-4 mt-5">Membership</h1>
      <p className="text-center">Become a member of the RMG Civil Rights Museum</p>
      <p>All memberships include:</p>
      <ul>
        <li>Free admission to museum programs and special events.</li>
        <li>10% discount on RMG Civil Rights Museum shop purchases.</li>
        <li>10% discount on admission for member guests.</li>
      </ul>
      <hr />
      <Row>
        <Col lg={10} xs={10}>
          <p className="lead mb-0">Individual <strong>$30</strong></p>
          <p className="mb-2">For 1 adult. This membership includes (1x) one time use guest pass</p>
        </Col>
        <Col lg={2} xs={2} className="align-self-center text-end">
          <a href="https://checkout.square.site/merchant/S3Z71JWRFY84A/checkout/EXWOOUPPXXTKJ7EXFD5O5GI2" className="btn btn-primary px-3"><strong>Join</strong></a>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col lg={10} xs={10}>
          <p className="lead mb-0">Family <strong>$60</strong></p>
          <p className="mb-2">For 2 adults and 2 children living in the same household 18 or younger. This
membership includes (2x) one time use guest passes.</p>
        </Col>
        <Col lg={2} xs={2} className="align-self-center text-end">
          <a href="https://checkout.square.site/merchant/S3Z71JWRFY84A/checkout/WIRV276QJUYXJEPPJCIOO55O" className="btn btn-primary px-3"><strong>Join</strong></a>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col lg={10} xs={10}>
          <p className="lead mb-0">Dual Senior <strong>$40</strong></p>
          <p className="mb-2">For 2 adults age 65 and above. Includes (2x) one time use guest passes.</p>
        </Col>
        <Col lg={2} xs={2} className="align-self-center text-end">
          <a href="https://checkout.square.site/merchant/S3Z71JWRFY84A/checkout/WWXYGM3GWZTX2MXR2LLGBGDJ" className="btn btn-primary px-3"><strong>Join</strong></a>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col lg={10} xs={10}>
          <p className="lead mb-0">Senior <strong>$25</strong></p>
          <p className="mb-2">For 1 adult age 65 and above. Includes (1x) one time use guest pass.</p>
        </Col>
        <Col lg={2} xs={2} className="align-self-center text-end">
          <a href="https://checkout.square.site/merchant/S3Z71JWRFY84A/checkout/T7TJLPF6Q65FLYE6MB4ERGVB" className="btn btn-primary px-3"><strong>Join</strong></a>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col lg={10} xs={10}>
          <p className="lead mb-0">Educator <strong>$25</strong></p>
          <p className="mb-2">For one adult employed as an educator. Must show proof of status. Includes
(1x) one time use guest pass.</p>
        </Col>
        <Col lg={2} xs={2} className="align-self-center text-end">
          <a href="https://checkout.square.site/merchant/S3Z71JWRFY84A/checkout/D4QP6MN2EHR54RPUW6ATE7YH" className="btn btn-primary px-3"><strong>Join</strong></a>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col lg={10} xs={10}>
          <p className="lead mb-0">Student <strong>$25</strong></p>
          <p className="mb-2">For student (age 13-25) with proof of student ID. Includes (1x) one time use
guest pass.</p>
        </Col>
        <Col lg={2} xs={2} className="align-self-center text-end">
          <a href="https://checkout.square.site/merchant/S3Z71JWRFY84A/checkout/6UFRQRIZMX3ZIG5HJ2CUIZOY" className="btn btn-primary px-3"><strong>Join</strong></a>
        </Col>
      </Row>

    </Container>
      
  </Layout>
)

export const Head = () => <Seo title="Membership" />

export default SecondPage